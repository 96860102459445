import commonAction from 'actions/commonAction';
import { PlanListing } from 'api/generated/enums';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS_ACTION = 'SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS';

export const searchOffExchangeRecommendedPlans = (
    stateAbbrev: string | undefined,
    zipCode: string | undefined,
    countyFips: string | undefined,
    year: number
) =>
    commonAction(
        async () =>
            new MarketplaceApi().searchPlans(
                stateAbbrev,
                zipCode,
                countyFips,
                year || new Date().getUTCFullYear(),
                PlanListing.OffExchange
            ),
        SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS_ACTION,
        {
            toastErrorMessage: `Failed to retrieve off-exchange plans for ${year}`,
        }
    );
