import commonAction from 'actions/commonAction';
import HrsApi from 'api/generated/HrsApi';

export const SET_PAYROLL_DEDUCTIONS_ACTION = 'SET_PAYROLL_DEDUCTIONS';

export const setPayrollDeductions = (payrollReportId: string | undefined, effectiveDate?: string) =>
    commonAction(
        async () => new HrsApi().setPayrollDeductions(payrollReportId, effectiveDate),
        SET_PAYROLL_DEDUCTIONS_ACTION,
        {
            toastErrorMessage: 'Failed to apply withholdings and Wage+ amounts',
            toastSuccessMessage: 'Successfully applied withholdings and Wage+ amounts',
        }
    );
