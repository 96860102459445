import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { IPathway, Pathway } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const EDIT_PATHWAY_ACTION = 'EDIT_PATHWAY';

export const editPathway = (pathway: IPathway) =>
    commonAction(
        async () => new PathwayBlueprintApi().editPathway(new Pathway(pathway)),
        EDIT_PATHWAY_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(pathway.pathwayBlueprintId));
            },
            toastErrorMessage: (response) =>
                `Unable to edit pathway. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully updated pathway.',
        }
    );
