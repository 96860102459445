import commonAction from 'actions/commonAction';
import HrsApi from 'api/generated/HrsApi';

export const SET_PAYROLL_DEDUCTIONS_FOR_USER_ACTION = 'SET_PAYROLL_DEDUCTIONS_FOR_USER';

export const setPayrollDeductionsForUser = (
    payrollReportId: string | undefined,
    payrollReportUserSnapshotId: string | undefined,
    effectiveDate?: string
) =>
    commonAction(
        async () =>
            new HrsApi().setPayrollDeductionsForUser(
                payrollReportId,
                payrollReportUserSnapshotId,
                effectiveDate
            ),
        SET_PAYROLL_DEDUCTIONS_FOR_USER_ACTION,
        {
            toastErrorMessage: 'Failed to apply withholdings and Wage+ amounts for user',
            toastSuccessMessage: 'Successfully applied withholdings and Wage+ amounts for user',
        }
    );
