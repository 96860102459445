/* !!! THIS FILE IS AUTO-GENERATED DO NOT MAKE MANUAL CHANGES !!! */
export const AccessOtherTeams = '32c181a0';
export const AddEnrollmentVerificationFile = '5d67596a';
export const ArchiveTeam = 'f25d6239';
export const ArchiveTeamNote = '9282e2ca';
export const ArchiveTeamRhFile = '94e16774';
export const ArchiveUserNote = '0111ec89';
export const ArchiveUserRhFile = '93455973';
export const CalculatePathwayBlueprint = '0e7c9daf';
export const CancelAdpSubscriptionService = '5b0d4d95';
export const CanManageOwnTeam = 'ab5d98ec';
export const CensusUpload = 'a79443c5';
export const ConnectToFinch = 'cccea0b1';
export const CreateAdpSubscriptionService = 'eb460b2f';
export const CreateEnrollmentVerification = 'ac71d6eb';
export const CreateMarketingRhFile = 'b970c141';
export const CreatePathway = 'a9304e2f';
export const CreatePathwayBlueprints = '9bcece3c';
export const CreateProposal = '1f5be1cb';
export const CreateReport = '4ac30ef5';
export const CreateRole = '1cd168c8';
export const CreateSelectedPlans = 'd79599e3';
export const CreateTeam = '4d51e650';
export const CreateTeamBenefit = 'e1f9d7bb';
export const CreateTeamBenefitRhFile = '33d56279';
export const CreateTeamNote = '95e0f461';
export const CreateTeamRhFile = 'e2372747';
export const CreateUser = 'bc97b11f';
export const CreateUserNote = 'c75ec474';
export const CreateUserRhFile = 'a5abbed2';
export const DeactivateRhUser = '8764de62';
export const DeleteEnrollmentVerification = '12fb3079';
export const DeleteMarketingRhFile = 'cc160a5d';
export const DeletePathway = '64707379';
export const DeletePathwayBlueprints = 'd3fb0f77';
export const DeletePayrollReports = 'EC638DB0';
export const DeleteReport = '8b89ecb1';
export const DeleteRole = '2e93a139';
export const DeleteSelectedPlans = 'daf98de8';
export const DeleteTeam = '5987400a';
export const DeleteTeamBenefit = '7e4165fe';
export const DeleteTeamBenefitRhFile = '1ce45f72';
export const DeleteTeamNote = '09de6212';
export const DeleteTeamRhFile = '83367acf';
export const DeleteUser = '6361799d';
export const DeleteUserNote = '6b94b7fa';
export const DeleteUserRhFile = 'c80a68c3';
export const DeleteUsersForOwnTeam = 'd0e0c78b';
export const DownloadEnrollmentCensus = '0644ae6c';
export const DownloadReimbursementReportRhFile = 'de6dfe2d';
export const DownloadTeamBenefitRhFile = '7139a299';
export const DownloadTeamRhFile = 'a4550700';
export const DownloadUserRhFile = '86c7c2ce';
export const EditEnrollmentVerification = '24d514a1';
export const EditLockedSubmittedExpense = '67vba97w';
export const EditMedicaidDetails = 'e72c23ff';
export const EditPathway = '416a122b';
export const EditPathwayBlueprints = 'c0d2cb03';
export const EditPayrollIntegrationConfiguration = '6f45355c';
export const EditPayrollReports = 'c6df480a';
export const EditProfilePicture = 'cfa576f1';
export const EditReport = '56198d7e';
export const EditRole = '58c23bc6';
export const EditSelectedPlans = '31cf2946';
export const EditSocialSecurityNumber = 'c487cb82';
export const EditSubmittedExpense = '011c32ad';
export const EditUserFlowTaskStatus = '12f804c9';
export const EditSurveyTypeToSend = 'a7882fa5';
export const EditTaxData = '26e014ee';
export const EditTeam = '8c896271';
export const EditTeamAdmins = '6ad0219b';
export const EditTeamAdvisor = '5e32cf31';
export const EditTeamBenefit = '72d72983';
export const EditTeamBenefitRhFile = 'd0e8b200';
export const EditTeamNote = '188487f7';
export const EditTeamRepresentative = '09b5b59f';
export const EditTeamRhFile = '477a17b7';
export const EditTeamUserAccess = '01227d35';
export const EditUser = '28e4e0d7';
export const EditUserBudget = '255c9354';
export const EditUserMajorMedicalCoverageLevel = 'f8a20334';
export const EditUserNote = '4297209e';
export const EditUserRhFile = '3cc4645a';
export const EditUserRoles = '9443f24f';
export const EditUserStatus = 'a7fda47a';
export const EditUserVerifiedInfo = 'b6c38a04';
export const GenerateHrsIntegrationKey = 'f77d1b47';
export const ImpersonateUsers = '675b8e0f';
export const InviteUsers = 'd2a02cc9';
export const LaunchTeam = '7fd630e4';
export const RecalculateBenefitCosts = '72286546';
export const RemoveEnrollmentVerificationFile = '7ff9553c';
export const ResendRenewalInvite = '90d1f2ff';
export const ResetAccountLockout = '6d57903c';
export const RunScheduledJob = '0468bd54';
export const SendEnrollmentVerificationReminder = '2b14760b';
export const SendSurveys = '3a8522cf';
export const SetPayrollSystemDeduction = 'f12404ab';
export const SetUserStatusEnrolled = '1efcb8c8';
export const SetUserStatusInactive = 'b01d6712';
export const SetUserStatusOffBoarding = '936e412d';
export const SetUserStatusWaived = '643b2534';
export const ShareReports = '1ae8b5da';
export const ShareTeamBenefitRhFile = '67930040';
export const ShareTeamRhFiles = '9994cd82';
export const ShareUserRhFiles = 'b417b299';
export const SubmitExpense = '1a6fa17f';
export const ViewHrsAssociateId = '5470fbe8';
export const ViewHrsIntegrationKey = '661da168';
export const ViewEnrollmentVerification = 'cb2a8461';
export const ViewInvoiceSummary = '49408e52';
export const ViewMedicaidDetails = '20e2f83a';
export const ViewMviEmployeeAnnualIncome = 'a2b9c4d7';
export const ViewPathwayBlueprints = 'd167b43e';
export const ViewPaymentsInfo = 'ddcc080e';
export const ViewPayrollReports = '985c6f7d';
export const ViewPayrollSystemId = 'b0c39db9';
export const ViewQualitativeAnswers = '4a8c6ec1';
export const ViewReports = '13e006b3';
export const ViewRoles = 'e139ccf6';
export const ViewSelectedPlans = '8496491a';
export const ViewSocialSecurityNumber = 'e5a2ca4d';
export const ViewSubmittedExpenses = '81da4e46';
export const ViewSurveyTypeToSend = 'fd0ca272';
export const ViewTaxData = '10322edf';
export const ViewTeamBenefit = '09729ef0';
export const ViewTeamNotes = '62167aa2';
export const ViewTeamRhFiles = '7c062fd0';
export const ViewTeamSharedPayrollReports = '62eb4b1f';
export const ViewTeamSharedReimbursementReports = 'a8a62deb';
export const ViewTeamUserAccess = '1cb76794';
export const ViewUserBudget = 'e8b2de5b';
export const ViewUserNotes = '8731d80d';
export const ViewUserRhFiles = '6a19cc3f';
export const ViewUserRoles = '77314e39';
export const ViewUsers = '54d24f63';
export const ViewUserFlows = 'd18e10a6';
export const ViewUserVerifiedInfo = 'cedf06cc';
export const ViewUserVerifiedInfoForOwnTeam = '890ec1e9';
export const ViewUserWageIncrease = '01d7da43';

