import commonAction from 'actions/commonAction';
import PayrollReportsApi from 'api/generated/PayrollReportsApi';

export const TOGGLE_PAYROLL_REPORT_SHARED_ACTION = 'TOGGLE_PAYROLL_REPORT_SHARED';

export const togglePayrollReportShared = (
    payrollReportId: string | undefined,
    sharingPrefix: '' | 'un-'
) =>
    commonAction(
        async () => new PayrollReportsApi().togglePayrollReportShared(payrollReportId),
        TOGGLE_PAYROLL_REPORT_SHARED_ACTION,
        {
            toastErrorMessage: (response) =>
                `${response.error.response?.data}`,
            toastSuccessMessage: `Payroll Report successfully ${sharingPrefix}shared`,
        }
    );
