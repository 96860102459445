import commonAction from 'actions/commonAction';
import FinchApi from 'api/generated/FinchApi';

export const SYNC_USER_WITH_FINCH = 'SYNC_USER_WITH_FINCH';

export const syncUserWithFinch = (userId: string | undefined) =>
    commonAction(async () => new FinchApi().syncUser(userId), SYNC_USER_WITH_FINCH, {
        toastErrorMessage: 'Failed to sync with Finch',
        toastSuccessMessage: 'Starting sync with Finch',
    });
