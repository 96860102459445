import commonAction from 'actions/commonAction';
import { getTeamProfile } from 'actions/team/getTeamProfile';
import FinchApi from 'api/generated/FinchApi';
import { CreateAccessTokenCommand } from 'api/generated/models';

export const CREATE_FINCH_ACCESS_TOKEN_ACTION = 'CREATE_FINCH_ACCESS_TOKEN_ACTION';

export const createFinchAccessToken = (code: string, teamId: string) =>
    commonAction(
        async () =>
            new FinchApi().createAccessToken(new CreateAccessTokenCommand({ code, teamId })),
        CREATE_FINCH_ACCESS_TOKEN_ACTION,
        {
            successHook: ({ dispatch }) => {
                dispatch(getTeamProfile(teamId, true));
            },
            toastErrorMessage: 'Failed to connect with Finch',
            toastSuccessMessage: 'Successfully connected to Finch',
        }
    );
