import commonAction from 'actions/commonAction';
import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { IPathway, Pathway } from 'api/generated/models';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const ADD_PATHWAY_ACTION = 'ADD_PATHWAY';

export const addPathway = (pathway: IPathway) =>
    commonAction(
        async () => new PathwayBlueprintApi().addPathway(new Pathway(pathway)),
        ADD_PATHWAY_ACTION,
        {
            nonSuccessHook: ({ dispatch }) => {
                dispatch(getPathwayBlueprint(pathway.pathwayBlueprintId));
            },
            toastErrorMessage: (response) =>
                `Unable to add pathway. ${response.error.response?.data}`,
            toastSuccessMessage: 'Successfully added pathway.',
        }
    );
